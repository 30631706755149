import React, { useState } from "react"
import { Container } from "react-bootstrap"
import Lightbox from "react-image-lightbox"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import OffPlanContact from "../OffPlanContact/OffPlanContact"
import "./OffplanBanner.scss"
import GetGGFXImage from "../common/site/GetGGFXImage"


const OffplanBanner = props => {
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const propertyImages = props.offplanData?.images
  var propertyLightImages =
    propertyImages &&
    propertyImages.map(img => (img.srcUrl ? img.srcUrl : img.url))
  const openPropertyImage = (e, ind) => {
    e.preventDefault()
    setPhotoIndex(ind)
    setIsOpen(true)
  }

  const imageCount = props.offplanData?.images?.length

  const largeImage = props.offplanData?.images[0]
  const smallImage = props.offplanData?.images?.slice(1, 3)
  const { isLargeScreen } = useDeviceMedia()

  const showMapComponent = () => {
    const getId = document.getElementById("map-component")
    getId.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  let processedImages = props.offplanData?.imagetransforms?.images_Transforms
  processedImages ||= JSON.stringify({})

  return (
   
    <div className="offplan-details-wrapper">
      {isLargeScreen ? (
        <div className="offplan-banner-sections">
          <div className="offplan-image-sections">
            <div className="large-image">
              <a
                href="javascript:void(0)"
                onClick={e => openPropertyImage(e, 0)}
              >
                <GetGGFXImage
                  imagename={"property.images.offplanDetailImg"}
                  imagesource={largeImage}
                  fallbackalt={"offplan Banner"}
                  imagetransformresult={processedImages}
                  id={props.offplanData?.strapiId}
                />
              </a>

              <div className="property-image-containers">
                {props.offplanData?.images &&
                  props.offplanData?.images?.length > 0 && (
                    <div className="view-images-property">
                      <a
                        href="javascript:void(0)"
                        onClick={e => openPropertyImage(e, 0)}
                      >
                        <i className="icon prop-list-image"></i>{" "}
                        <span>{imageCount} Photos</span>
                      </a>
                    </div>
                  )}
                <div className="property-view-map">
                  <a
                    href="javascript:void(0)"
                    onClick={() => showMapComponent()}
                  >
                    <i className="icon map-pin" /> <span>Location</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="offplan-contact-sections">
            <OffPlanContact offplanData={props.offplanData} />
          </div>
        </div>
      ) : (
        <div className="offplan-image-sections">
          <Container className="offplan-detail-container offplan-container_padding">
            <div className="offplan-image-contact-container">
              <div className="offplan-contact-sections">
                <OffPlanContact offplanData={props.offplanData} />
              </div>
              <div className="large-image img-zoom">
                <a
                  href="javascript:void(0)"
                  onClick={e => openPropertyImage(e, 0)}
                >
                  <GetGGFXImage
                    imagename={"property.images.offplanDetailImg"}
                    imagesource={largeImage}
                    fallbackalt={"offplan Banner"}
                    imagetransformresult={processedImages}
                    id={props.offplanData?.strapiId}
                  />
                </a>
                <div className="property-image-containers">
                  {props.offplanData?.images &&
                    props.offplanData?.images?.length > 0 && (
                      <div className="view-images-property">
                        <a
                          href="javascript:void(0)"
                          onClick={e => openPropertyImage(e, 0)}
                        >
                          <i className="icon prop-list-image"></i>{" "}
                          <span>{imageCount} Photos</span>
                        </a>
                      </div>
                    )}

                  <div className="property-view-map">
                    <a
                      href="javascript:void(0)"
                      onClick={() => showMapComponent()}
                    >
                      <i className="icon map-pin" /> <span>View on map</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
      {isOpen && (
        <Lightbox
          mainSrc={propertyLightImages[photoIndex]}
          nextSrc={
            propertyLightImages[(photoIndex + 1) % propertyLightImages.length]
          }
          prevSrc={
            propertyLightImages[
              (photoIndex + propertyLightImages.length - 1) %
                propertyLightImages.length
            ]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + propertyLightImages.length - 1) %
                propertyLightImages.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
          }
        />
      )}
    </div>
   
  )
}

export default OffplanBanner
