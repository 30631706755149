import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import StayUpdatedForm from "../forms/StayUpdatedForm"

const StayUpdatedModule = () => {
  const [modalShow, setModalShow] = useState(false)

  const closeModal = () => {
    setModalShow(false)
    localStorage.setItem("showpopup", false)
  }
  useEffect(() => {
    const showPopup =
      localStorage.getItem("showpopup") === null
        ? true
        : localStorage.getItem("showpopup") === "false"
        ? false
        : false

    if (showPopup) {
      setTimeout(() => {
        setModalShow(true)
      }, 10000)
    }
  }, [])
  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="more-filters-modal stay-updated"
        id="filter-modal"
        // fullscreen={"xl-down"}
      >
        <button
          className="stay-button-form"
          type="button"
          onClick={() => closeModal()}
        >
          <i className="icon cancel-icon" />
        </button>
        <Modal.Body>
          <div className="modal-body-container">
            <StayUpdatedForm />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default StayUpdatedModule
