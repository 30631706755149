import React from "react"
import { Container } from "react-bootstrap"
import ReadMore from "../ReadMore/ReadMore"
import "./OffplanInformation.scss"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { fadeInFromLeft, fadeInFromRight,inViewOptions2,containerVariants } from "../utils/animation"
import voca from "voca"

const OffplanInformation = props => {
  const offplanData = props.offplanData
  return (
    <InView {...inViewOptions2}>
    {({ ref, inView }) => (
      <motion.div
        className=""
        ref={ref}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={containerVariants}
      >
    <div className="offplan-info-wrapper">
      <Container className="offplan-detail-container">
        <div className="offplan-info">
          <div className="left-info">
            <motion.div variants={fadeInFromLeft} className="about-section">
              <h3 className="heading">About</h3>
              <p className="desciption-title">Description</p>
              {offplanData?.long_description && (
                <div className="property-long-description" id="contentsection">
                  <ReadMore
                    content={offplanData?.long_description}
                    height={260}
                    className={"description"}
                  />
                </div>
              )}
            </motion.div>

            <motion.div variants={fadeInFromRight} className="details-section">
              <h4 className="detail">Details</h4>
              <div className="offplan-details-section-wrap">
                <div className="location-info item-1">
                  <p className="location head">Location</p>
                  <p>{offplanData?.display_address}</p>
                  <div className="horizontal-line"></div>
                </div>
                {offplanData?.developer_name&&
                <div className="developer-info item-2">
                  <p className="developer head">Developer</p>
                  <p>{offplanData?.developer_name}</p>
                  <div className="horizontal-line"></div>
                </div>
                  }
                <div className="development-type item-3">
                  <p className="type head">Development Type</p>
                  <p>{offplanData?.building[0]}</p>
                  <div className="horizontal-line"></div>
                </div>
                <div className="status-info item-4">
                  <p className="status head">Status</p>
                  <p>{voca.capitalize(voca.replace(offplanData?.extra?.completion_status, '_', ' '))}</p>
                  {/* <div className="horizontal-line"></div> */}
                </div>
                {/* <div className="completion-date item-5">
                  <p className="completion head">Completion Date</p>
                  <p>November 2024</p>
                </div> */}
              </div>
            </motion.div>
          </div>
        </div>
      </Container>
    </div>
    </motion.div>
      )}
    </InView>
  )
}

export default OffplanInformation
