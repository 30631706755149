import clsx from "clsx"
import React from "react"
import Footer from "../Footer/Footer"
import Header from "../Header/Header"
import StayUpdatedModule from "../StayUpdatedModule/StayUpdatedModule"
import "./Layout.scss"

const Layout = ({ children, pageLayout, Header_Type, className,popularSearch,GQLPage}) => {
  const showBugherd = process.env.GATSBY_SHOW_BUGHERD

  return (
    <div className={clsx("layout-wrap", className)}>
      <Header pageLayout={pageLayout} Header_Type={Header_Type} showBugherd={showBugherd} />
      <StayUpdatedModule />
      {children}
      <Footer pageLayout={pageLayout} categoryType={popularSearch} GQLPage={GQLPage}/>
    </div>
  )
}

export default Layout
