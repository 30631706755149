import React from "react"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { graphql } from "gatsby"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import OffplanBanner from "../components/OffplanBanner/OffplanBanner"
import OffplanInformation from "../components/OffplanInformation/OffplanInformation"
import OffplanGallery from "../components/OffplanGallery/OffplanGallery"
import Map from "../components/PropertyMap/Map"
import DevelopmentProperties from "../components/DevelopmentProperties/DevelopmentProperties"
import Layout from "../components/Layout/Layout"
import { offplanForSaleURL } from "../site/urls"
import logoBlack from "../images/logo-black.svg"
import SEO from "../components/Seo/seo"
import { numberFormat } from "../components/common/site/utils"
import NotFoundPage from "../pages/404"
import useCompanyInfo from "../hooks/useCompanyInfo"
import useDeviceMedia from "../hooks/useDeviceMedia"
import { getWhatsAppURL } from "../components/common/site/utils"
import balckPhoneImg from "../images/black-phone.svg"
import whatsappImg from "../images/whatsapp.svg"

const OffplanDetailTemplate = ({ data }) => {
  const { phone } = useCompanyInfo()
  const {isLargeScreen}=useDeviceMedia()
  // const PROPERTY_DETAILS = gql`
  //   query GetProperty($id: String!) {

  //     }
  //   }
  // `

  // const pageurl = typeof window !== "undefined" ? window.location.href : ""
  // let splitQuery = pageurl.split("?")
  // let mypageurl = splitQuery[0]
  // let property_slug = mypageurl.split("-")
  // let id = property_slug[property_slug.length - 1]
  // id = id.replace("/", "")

  // const { loading, error, data } = useQuery(PROPERTY_DETAILS, {
  //   variables: { id: id },
  // })


  // Loading logo
  // if (loading){
  //   return (
  //     <section className={"loader-wrapper"}>
  //       <div id="loader-wrapper">
  //         <div id="loader" class="new-loader">
  //           <img
  //             className="logo-white loader-logo"
  //             src={logoBlack}
  //             alt="logo"
  //           />
  //         </div>
  //       </div>
  //     </section>
  //   )
  //   }
  // Loading logo

  // if(data?.properties?.length===0){
  //   return (
  //     <NotFoundPage/>
  //   )
  // }

  const offplanData = data?.strapiProperty

  const propertyStatus = offplanData?.status?.toLowerCase()
  const staticURLs = [
    {
      url: `${offplanForSaleURL}in-dubai/`,
      label: `Offplan properties ${propertyStatus} in Dubai`,
    },
    { url: "", label: offplanData?.display_address },
  ]

  const building=offplanData?.building?.length>0?offplanData?.building[0]:""
  const action="for sale"

  const title=`Off plan ${building} ${action} with ${offplanData?.bedroom} bedrooms in ${offplanData?.display_address} at ${numberFormat(offplanData?.price)}`
  const description=`Know the details of off plan ${building} ${action} with ${offplanData?.bedroom} bedrooms in ${offplanData?.display_address} at ${numberFormat(offplanData?.price)}. Book a viewing to get assistance in finding the right ${building} for you.`
  
  let seo_image_url = ""
  seo_image_url = offplanData?.images && offplanData.images.length > 0 && offplanData.images[0].url;

  return (
    <div className="offplan-details-page">
      
      <SEO title={title} description={description} image={seo_image_url}/>
      <Layout pageLayout="search-results-page" popularSearch="category1" >
         {!isLargeScreen&&
       <div className="social-section">
          <a href={getWhatsAppURL(phone)} target="_blank">
            <img
              src={whatsappImg}
              alt="social-whatsapp"
              className="social-icon social-whatsapp"
            />
          </a>
          <a href={"tel:" + phone}>
            <img
              src={balckPhoneImg}
              alt="social-phone"
              className="social-icon social-phone"
            />
          </a>
        </div>
        }
        <Breadcrumbs staticURLs={staticURLs} page="offplan-detail-page" />
        <OffplanBanner offplanData={offplanData} />
        <OffplanInformation offplanData={offplanData} />
        <OffplanGallery offplanData={offplanData} />
        <Map property_details_map_data={offplanData} />
        <DevelopmentProperties offplanData={offplanData} />
      </Layout>
    </div>
  )
}

export default OffplanDetailTemplate

export const query = graphql`
  query ($strapiId: String) {
    strapiProperty(strapiId: {eq: $strapiId}) {
      id
      strapiId
      accomadation_summary
      area
      available_from
      bathroom
      bedroom
      building
      crm_negotiator_id {
        email
        name
        phone
        url
      }
      department
      description
      display_address
      extra {
        unit_reference_no
        unit_model
        tenanted
        property_name
        primary_view
        price_on_application
        preview_link
        permit_number
        off_plan
        no_of_rooms
        fitted
        featured
        exclusive
        completion_status
        company_name
      }
      floorarea_min
      floorarea_type
      developer_name
      address {
        address1
        address2
        address3
        address4
        building_name
        postcode
      }
      images {
        srcUrl
        url
      }
      imagetransforms {
        images_Transforms
      }
      long_description
      price
      price_qualifier
      property_url
      slug
      title
      latitude
      status
      longitude
    }
  }
`
