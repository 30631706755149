import { Link } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import { Property_Area } from "../../queries/common_use_query"
import CustomSlider from "../CustomSlider/CustomSlider"
import "./DevelopmentProperties.scss"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { inViewOptions2,containerVariants, fadeInFromTop } from "../utils/animation"


const DevelopmentProperties = props => {
  const { data } = Property_Area(
    props.offplanData?.area,
    props.offplanData?.department
  )
  const propertyLists = data?.properties
  if (!propertyLists) {
    return null
  }
  const offPlanData = props.offplanData

  let uriStr = "off-plan-property-for-sale"

  return (
    <InView {...inViewOptions2}>
    {({ ref, inView }) => (
      <motion.div
        className=""
        ref={ref}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={containerVariants}
      >
    <>
      {propertyLists && propertyLists.length > 0 && (
        <motion.div variants={fadeInFromTop} className="more-developments-wrapper">
          <Container className="offplan-detail-container">
            <h2>more developments in {offPlanData?.area}</h2>

            <CustomSlider className="more-developments-slider" length={propertyLists?.length}>
              {propertyLists.map((item, index) => (
                <div className="more-developments-card">
                  <Link
                    to={`/${uriStr}/${item?.slug}-${item?.id}/`}
                  >
                    <div className="image-section">
                      <img
                        src={item.images[0].url}
                        alt={item.address?.address1}
                      />
                      <div className="image-tag">
                        <p className="tag-name">{item?.building[0]}</p>
                      </div>
                    </div>
                  </Link>
                  <div className="content">
                    <p className="price">
                      AED {parseFloat(item?.price).toLocaleString()}
                    </p>
                    <Link
                      to={`/${uriStr}/${item?.slug}-${item?.id}/`}
                    >
                      <p className="address">{item.address?.address1}</p>
                    </Link>
                    <p className="description">The Plaza by Select Group</p>
                  </div>
                </div>
              ))}
            </CustomSlider>
          </Container>
        </motion.div>
      )}
    </>
    </motion.div>
    )}
  </InView>
  )
}

export default DevelopmentProperties
