import React, { useRef } from "react"
import TermsPolicySection from "../TermsPolicySection/TermsPolicySection"
import { postFormData } from "./api/Api"
import Form from "./elements/Form"
import TextInput from "./elements/TextInput"
import { EnquirySchema } from "./schemas"
import "./Form.scss"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import FORM_CONFIG, { SUCCESS_MSG } from "./config"
import Recaptcha from "./elements/Recaptcha"
import { SendMail } from "../common/site/utils"
import TextAreaInput from "./elements/TextAreaInput"
import { graphql, useStaticQuery } from "gatsby"
import parse from "html-react-parser"

const StayUpdatedForm = () => {
  const data = useStaticQuery(graphql`
    query getData {
      glstrapi {
        globalModule {
          Form_Intro_Text
          Form_Title
        }
      }
    }
  `)

  const dataItem = data.glstrapi.globalModule

  const recaptchaRef = useRef()

  const onSubmit = async data => {
    localStorage.setItem("showpopup", false)
    const token = await recaptchaRef.current.executeAsync()
    if (token) {
      let postData = { ...data, ...FORM_CONFIG.stay_updated }
      recaptchaRef.current.reset()
      postFormData(postData).then(async apiRes => {
        await SendMail(postData)
      })
    }
  }
  return (
    <>
      <div className="stay-updated-content">
        <h4>{dataItem.Form_Title}</h4>
        <p className="stay-intro-text">{parse(dataItem.Form_Intro_Text)}</p>
      </div>
      <Form
        className="custom-modal-form"
        validationSchema={EnquirySchema}
        onSubmit={onSubmit}
        successMsg={SUCCESS_MSG}
      >
        <div className="form-row">
          <TextInput
            name="firstname"
            placeholder="First Name"
            position="left"
          />
          <TextInput name="lastname" placeholder="Last Name" />
        </div>
        <div className="form-row">
          <TextInput name="email" placeholder="Email" position="left" />
          <TextInput
            name="telephone"
            placeholder="Mobile Number"
            type="number"
          />
        </div>
        <div className="form-row">
          <TextAreaInput name="message" placeholder="Message" />
        </div>

        <button className="button button-black" type="submit">
          Register
        </button>
      </Form>
      <TermsPolicySection />
      <div className="d-none">
        <Recaptcha recaptchaRef={recaptchaRef} />
      </div>
    </>
  )
}

export default StayUpdatedForm
