import React, { useState } from "react"
import { Container } from "react-bootstrap"
import "./OffplanGallery.scss"
import Lightbox from "react-image-lightbox"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { fadeInFromLeft, fadeInFromRight,inViewOptions2,containerVariants, fadeInFromTop } from "../utils/animation"

const OffplanGallery = props => {
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const offplanData = props.offplanData

  const { isMobile, isLargeScreen } = useDeviceMedia()

  const deviceMobile = offplanData?.images?.slice(1, 2)
  const deviceTablet = offplanData?.images?.slice(1, 3)
  const largeDevice = offplanData?.images?.slice(1, 4)
  const galleryImage = isMobile
    ? deviceMobile
    : isLargeScreen
    ? deviceTablet
    : largeDevice

  const propertyImages = props.offplanData?.images
  var propertyLightImages =
    propertyImages &&
    propertyImages.map(img => (img.srcUrl ? img.srcUrl : img.url))
  const openPropertyImage = (e, ind) => {
    e.preventDefault()
    setPhotoIndex(ind)
    setIsOpen(true)
  }
  return (
    <InView {...inViewOptions2}>
    {({ ref, inView }) => (
      <motion.div
        className=""
        ref={ref}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={containerVariants}
      >
    <div className="offplan-gallery-wrapper">
      <Container className="offplan-detail-container">
        <div className="gallery-section">
          <motion.h3 variants={fadeInFromLeft}>Gallery</motion.h3>

          <motion.div variants={fadeInFromTop} className="gallery-image">
            {galleryImage?.length > 0 &&
              galleryImage.map(gallery => (
                <>
                  <img src={gallery.url} alt="Gallery Image" />
                </>
              ))}
            <div className="gallery-image-containers">
              {offplanData?.images && offplanData?.images?.length > 0 && (
                <div className="view-images-property">
                  <a
                    href="javascript:void(0)"
                    onClick={e => openPropertyImage(e, 0)}
                  >
                    <i className="icon prop-list-image"></i>{" "}
                    <span>MORE PHOTOS</span>
                  </a>
                </div>
              )}
            </div>
          </motion.div>
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={propertyLightImages[photoIndex]}
            nextSrc={
              propertyLightImages[(photoIndex + 1) % propertyLightImages.length]
            }
            prevSrc={
              propertyLightImages[
                (photoIndex + propertyLightImages.length - 1) %
                  propertyLightImages.length
              ]
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + propertyLightImages.length - 1) %
                  propertyLightImages.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
            }
          />
        )}
      </Container>
    </div>
    </motion.div>
      )}
    </InView>
  )
}

export default OffplanGallery
