import { Link } from "gatsby"
import React, { useState } from "react"
import { Container } from "react-bootstrap"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import { People_Email } from "../../queries/common_use_query"
import { bookViewing } from "../../site/urls"
import { getWhatsAppURL } from "../common/site/utils"
import SocialShareComponent from "../SocialShareComponent/SocialShareComponent"
import "./OffPlanContact.scss"

const OffPlanContact = props => {
  const [shareIcons, setShareIcons] = useState(false)
  const offplanData = props.offplanData
  const companyLogo = offplanData?.extra?.company_logo
  const { isMobile } = useDeviceMedia()
  const shareurl = typeof window !== "undefined" ? window.location.href : ""
  const openShareicons = () => {
    setShareIcons(true)
    if (shareIcons === true) {
      setShareIcons(false)
    }
  }
  const negotiatorEmail = offplanData?.crm_negotiator_id?.email
  const peopleData = People_Email(negotiatorEmail)
  const teamData =
    peopleData?.data?.teams?.length > 0 && peopleData?.data?.teams[0]

  return (
    <div className="offplan-contact-wrapper">
      <Container className="offplan-desktop-container">
        <div className="offplan-property-contact">
          {companyLogo && (
            <div className="company-logo">
              <img src={companyLogo} alt="logo" />
            </div>
          )}
          <h1>{offplanData?.title}</h1>
          <p className="display-address">{offplanData?.display_address}</p>
          <p className="small-description">{offplanData?.description}</p>
          {teamData && (
            <div className="offplan-property-contact-ctas">
                <a  className="button button-black" href={"tel:" + teamData?.mobile_no}>
                  <i className="icon call-icon" />{" "}
                  <span>{isMobile ? "" : "call"}</span>
                </a>
                <Link
                className="button button-black middle_button"
                  to={bookViewing}
                  state={{
                    data: {
                      property: props.offplanData,
                      shareurl,
                    },
                  }}
                >
                  <i className="icon envelope-icon" />{" "}
                  <span>{isMobile ? "" : "email"}</span>
                </Link>
                <a className="button button-black" href={getWhatsAppURL(teamData?.mobile_no)} target="_blank">
                  <i className="icon white-Whatsapp" />
                  <span>{isMobile ? "" : "whatsapp"}</span>
                </a>
            </div>
          )}

          <div className="share-property" onClick={() => openShareicons()}>
            <i className="icon black-share-icon" />
            <span>Share this Property</span>
            {shareIcons && (
              <SocialShareComponent
                openShareicons={openShareicons}
                shareurl={shareurl}
              />
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default OffPlanContact
